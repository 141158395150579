import { classNames } from '@/shared/lib/classNames/classNames';
import cls from './Menu.module.scss';
import { AppLink, AppLinkTheme } from '@/shared/ui/AppLink';

interface MenuProps {
    className?: string;
    isMobile?: boolean;
}

export const Menu = (props: MenuProps) => {
    const { className, isMobile } = props;

    return (
        <nav className={classNames(cls.Menu, { [cls.isMobile]: isMobile }, [className])}>
            <ul className={cls.list}>
                <li className={cls.item}>
                    <AppLink //
                        className={cls.blockItem}
                        to="/about"
                        theme={AppLinkTheme.NAVIGATION}
                    >
                        О компании
                    </AppLink>
                </li>
                <li className={cls.item}>
                    <AppLink //
                        to="/assortment"
                        className={cls.blockItem}
                        theme={AppLinkTheme.NAVIGATION}
                    >
                        Ассортимент
                    </AppLink>
                </li>
            </ul>
        </nav>
    );
};
