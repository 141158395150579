import { Wrapper } from '@/shared/ui/Wrapper';
import { classNames } from '@/shared/lib/classNames';
import cls from './Footer.module.scss';

interface FooterProps {
    className?: string;
}
export const Footer = ({ className }: FooterProps) => {
    return (
        <footer className={classNames(cls.Footer, {}, [className])}>
            <Wrapper>
                <div className={cls.flex}>
                    <div className={cls.rights}>© 2024 Tesla batteries a.s.</div>
                    <div className={cls.left}>
                        Телефон горячей линии:{' '}
                        <a href="tel:88005506332" className={cls.link}>
                            8 (800) 550-63-32
                        </a>
                    </div>
                </div>
            </Wrapper>
        </footer>
    );
};
