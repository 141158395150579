import { ChangeEvent } from 'react';
import { classNames } from '@/shared/lib/classNames/classNames';
import Battery from '@/shared/assets/img/common/battery.png';
import cls from './SwitchLanguage.module.scss';

interface IOption {
    label: string;
    value: string;
}

const options: IOption[] = [
    { label: 'ru', value: 'http://teslabatteries.su/' },
    { label: 'cs', value: 'https://www.teslabatteries.com/batteries/' },
    { label: 'en', value: 'https://www.teslabatteries.com/batteries/en/' },
    { label: 'de', value: 'https://www.teslabatteries.com/batteries/de/' },
    { label: 'at', value: 'https://www.teslabatteries.com/batteries/at/' },
    { label: 'fr', value: 'https://www.teslabatteries.com/batteries/fr/' },
    { label: 'uk', value: 'https://www.teslabatteries.com/batteries/uk/' },
    { label: 'it', value: 'https://www.teslabatteries.com/batteries/it/' },
    { label: 'es', value: 'https://www.teslabatteries.com/batteries/es/' },
    { label: 'pl', value: 'https://www.teslabatteries.com/batteries/pl/' },
    { label: 'bg', value: 'https://www.teslabatteries.com/batteries/bg/' },
    { label: 'el', value: 'https://www.teslabatteries.com/batteries/el/' },
    { label: 'ko', value: 'https://www.teslabatteries.com/batteries/ko/' },
];

interface SwitchLanguageProps {
    className?: string;
}

export const SwitchLanguage = (props: SwitchLanguageProps) => {
    const { className } = props;

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const url = event.target.value;
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className={classNames(cls.SwitchLanguage, {}, [className])}>
            <select onChange={handleChange} defaultValue="ru" className={cls.select}>
                {options.map((option) => (
                    <option key={option.value} value={option.value} disabled={option.value === ''}>
                        {option.label}
                    </option>
                ))}
            </select>
            <img className={cls.batteryIcon} src={Battery} alt="Иконка батарейки" />
        </div>
    );
};
